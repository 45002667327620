export default {
  welcome: 'Welkom',
  yes: 'Ja',
  no: 'Nee',
  ra: 'RA',
  m: 'M',
  eu: 'EU',
  loading: 'Laden...',
  recentlyUpdated: 'Onlangs bijgewerkt',
  tutorial: {
    dashboard: [
      {
        title: 'Welkom bij uw nieuwe online applicatie.',
        description:
          'Vanaf nu word je gevraagd deze applicatie te gebruiken voor de GMP+ Risk Management Tools. Dit is belangrijk, omdat uw gebruik van deze omgeving ons helpt eventuele resterende problemen te identificeren. Houd er rekening mee dat dit de eerste release is; daarom zal niet alles op dit moment volledig functioneren. We zullen uw onboarding zo gemakkelijk mogelijk maken door een korte maar nuttige demo door de hele omgeving te bieden. Je kunt de demo starten met de knop aan uw rechterkant. ',
      },
      {
        title: 'Feedback',
        description:
          'Heb je feedback voor ons over deze applicatie? Door op deze knop te klikken, kun je ons op de hoogte stellen van eventuele problemen. Je kunt deze knop op elke pagina gebruiken.',
      },
      {
        title: 'Snelle links',
        description:
          'Hier vind je snelle links naar de GMP+ scheme documenten, risicobeoordelingen en de GMP+ Company database. Je kunt ook de navigatie aan de linkerkant gebruiken. Hier vind je ook het Early Warning System.',
      },
      {
        title: 'Helpdesk',
        description:
          'Wij zijn hier om je op elke mogelijke manier te helpen. Neem eenvoudig contact met ons op via deze knop als je vragen hebt. Wil je feedback geven? Gebruik dan de feedbackknop.',
      },
      {
        title: 'Navigatie vergrendelen',
        description: 'Vergrendel de navigatie eenvoudig hier.',
      },
      {
        title: 'Rondleiding',
        description: 'Start deze demo rondleiding met één klik.',
      },
    ],
    button: {
      next: 'Volgende',
      previous: 'Vorige',
      done: 'Klaar',
    },
  },
  nav: {
    profile: {
      touLink: '/tou/Terms of Use GMP+ Platform - NL.pdf',
      tou: 'Gebruiksvoorwaarden',
      switch: {
        portal: 'Naar Client Portal',
        management: 'Naar Management',
      },
      settings: 'Instellingen',
      logout: 'Uitloggen',
      language: {
        title: 'Taal',
        en: 'Engels',
        de: 'Duits',
        nl: 'Nederlands',
      },
    },
    detailNavBar: {
      issuerId: 'Uitgevers ID',
      myProducts: 'Mijn producten',
      countryOfOrigin: 'Land van herkomst',
      methods: 'Methoden',
      parameter: 'Parameter',
      producerSupplier: 'Producent & leverancier',
      product: 'Product',
      generalInformation: 'Algemene informatie',
      tracingData: 'Tracering gegevens',
      analysisReport: 'Analyserapport',
      products: 'Producten',
      processingAids: 'Technische hulpmiddelen',
      flowcharts: 'Stroomdiagrammen',
      processSteps: 'Processtappen',
      processStep: 'Processtap',
      processLinking: 'Proceskoppeling',
      hazards: 'Gevaren',
      feedMaterials: 'Productlijst',
      controlMeasures: 'Beheersmaatregelen',
      limits: 'Limieten',
      productInformation: 'Productinformatie',
      riskAssessments: 'Risicobeoordelingen',
      documents: 'Documenten',
      factSheets: 'Fact sheets',
      processingAidGroup: 'Technische hulpmiddelen groep',
      hazardInformation: 'Gevaren informatie',
      undesirableSubstances: 'Ongewenste stoffen',
      processingAidsInformation: 'Technische hulpmiddelen informatie',
      selectLocation: 'Selecteer locatie',
      defineStartAndEndDate: 'Definieer start- en einddatum',
      childHazards: 'Subgevaren',
    },
    menu: {
      analysisSets: 'Analysesets',
      myProducts: 'Mijn producten',
      certificationBodies: 'Certificatie instellingen',
      certifiedCompanies: 'Gecertificeerde bedrijven',
      codeLists: 'Codelijsten',
      controlMeasureGroups: 'Beheersmaatregelgroepen',
      controlMeasures: 'Beheersmaatregelen',
      dashboard: 'Dashboard',
      digitalSubmission: 'Digitale inzending',
      earlyWarningSystem: 'Early Warning System',
      feedSchemeDocuments: 'GMP+ scheme documenten',
      framework: 'Kader',
      gmpCompanyDatabase: 'GMP+ Company database',
      hazards: 'Gevaren',
      laboratories: 'Laboratoria verrichtingen',
      mainMenu: 'Hoofdmenu',
      management: 'Management',
      methods: 'Methoden',
      monitoringDatabase: 'Monitoring database',
      monitoringGroups: 'Monitoringgroepen',
      myGroups: 'Mijn groepen',
      groupManagement: 'Beheer groepsleden',
      myMonitoring: 'Mijn monitoring',
      otherCertificationSchemes: "Andere Certificatie schema's",
      partners: 'Partners',
      processingAidGroups: 'Technische hulpmiddelen groepen',
      processingAids: 'Technische hulpmiddelen',
      processStepGroups: 'Processtapgroepen',
      processSteps: 'Processtappen',
      productGroups: 'Productgroepen',
      products: 'Producten',
      reports: 'Rapporten',
      requirements: 'Vereisten',
      riskAssessments: 'Risicobeoordelingen',
      riskManagementTools: 'Risk Management tools',
      support: 'Ondersteuning',
      undesirableSubstances: 'Ongewenste stoffen',
      units: 'Eenheden',
      managementGroupMembers: 'Groepsleden beheren',
      acceptedFeedMaterials: 'Productlijst',
      cbLogin: 'CB/Auditor/Committee Portal',
      exemptions: 'Ontheffingen',
      helpdesk: 'Support',
      helpdeskLink: 'https://www.gmpplus.org/nl/support-platform/',
    },
  },
  form: {
    unit: {
      updateTitle: 'Eenheid bijwerken',
      createTitle: 'Eenheid aanmaken',
      updateDescription: 'Vul de vereiste velden in.',
      createDescription: 'Vul de vereiste velden in.',
    },
    processSteps: {
      updateTitle: 'Processtappen bijwerken',
      createTitle: 'Processtappen aanmaken',
      createDescription: 'Vul de vereiste velden in.',
    },
    processingAidGroup: {
      updateTitle: 'Technische hulpmiddelen groep bijwerken',
      createTitle: 'Technische hulpmiddelen groep aanmaken',
      updateDescription: 'Vul de vereiste velden in.',
      createDescription: 'Vul de vereiste velden in.',
    },
    products: {
      updateTitle: 'Producten bijwerken',
      createTitle: 'Producten aanmaken',
      createDescription: 'Vul de vereiste velden in.',
    },
    processingAids: {
      updateTitle: 'Technische hulpmiddelen bijwerken',
      createTitle: 'Technische hulpmiddelen aanmaken',
      createDescription: 'Vul de vereiste velden in.',
    },
    flowchart: {
      updateTitle: 'Stroomdiagram bijwerken',
      createTitle: 'Stroomdiagram aanmaken',
      createDescription: 'Voeg stroomdiagrammen toe aan de risicobeoordeling.',
    },
    riskAssessment: {
      updateTitle: 'Risicobeoordeling bijwerken',
      createTitle: 'Risicobeoordeling aanmaken',
      createDescription: 'Vul de vereiste velden in.',
    },
    processLinking: {
      updateTitle: 'Proceskoppeling bijwerken',
      createTitle: 'Proceskoppeling aanmaken',
    },
    hazards: {
      updateTitle: 'Gevaren bijwerken',
      createTitle: 'Gevaren aanmaken',
    },
  },
  dialog: {
    cancelChanges: {
      title: 'Weet je zeker dat je wilt annuleren?',
      description: 'Alle niet-opgeslagen wijzigingen gaan verloren.',
    },
  },
  detail: {
    table: {
      product: 'Product',
      dateReplied: 'Datum beantwoord',
      dateReceived: 'Datum ontvangen',
      name: 'Naam',
      code: 'Code',
      active: 'Actief',
      productGroup: 'Productgroep',
      activeRiskAssessmentMonitoring: 'Actief voor risicobeoordeling',
      euProduct: 'EU-product',
      file: 'Bestand',
      versionDate: 'Versiedatum',
      sortSequence: 'Sorteervolgorde',
      description: 'Beschrijving',
      version: 'Versie',
      publicationDate: 'Publicatiedatum',
      changeDate: 'Wijzigingsdatum',
      status: 'Status',
      hazard: 'Gevaar',
      hazardName: 'Gevaarnaam',
      referenceDiagram: 'Referentiediagram',
      likelyOccurrence: 'Waarschijnlijke optreden',
      explanation: 'Uitleg',
      controlMeasures: 'Beheersmaatregelen',
      processStepName: 'Processtapnaam',
      severity: 'Ernst',
      parentHazard: 'Oorspronkelijk gevaar',
      category: 'Categorie',
      location: 'Locatie',
      order: 'Volgorde',
      detectionLimit: 'Detectielimiet',
      unit: 'Eenheid',
      method: 'Methode',
      processingAidGroup: 'Technische hulpmiddelen groep',
      factsheet: 'Fact sheet',
      feedMaterials: 'Productlijst',
      search: 'Zoeken',
      selectUndesirableSubstances: 'Selecteer ongewenste stoffen',
      selectProcessingAids: 'Selecteer technische hulpmiddelen',
      selectFeedMaterials: 'Selecteer producten',
      rowsPerPage: 'Rijen per pagina',
      rows: 'Rijen',
      definition: 'Definitie',
      synonyms: 'Synoniemen',
      showAllSelectedItems: 'Toon alle geselecteerde items',
      sampleLevel: 'Monsterniveau',
      sampleDate: 'Monster datum',
      sampleDateRange: 'Monster datumbereik',
      edit: 'Bewerken',
      sampleNumber: 'Monsternummer',
      typeOfAnalysis: 'Analysetype',
      batchNumber: 'Batchnummer',
      origin: 'Herkomst',
      producer: 'Producent',
      supplier: 'Leverancier',
      characteristics: 'Kenmerken',
      equalsResult: '=',
      greaterThanResult: '>',
      lessThanResult: '<',
      none: '-',
      dateOfReport: 'Rapportdatum',
      note: 'Notitie',
      lowerThanResult: '<',
      plannedSampleNumber: 'Gepland monsternummer',
      plannedSampleDate: 'Geplande monster datum',
      moist: 'Vocht',
      hazardType: 'Gevaar type',
      unitCode: 'Eenheid code',
      unitName: 'Eenheid name',
      action: 'Actie',
      productName: 'Productnaam',
      sort: 'Sorteren',
      group: 'Groep',
      remarks: 'Opmerkingen',
      anonymous: 'Anoniem',
      GMPPlusCommunity: 'GMP+ Gemeenschap',
      selected: 'Geselecteerd',
      registrationNumber: 'Registratienummer',
      startDate: 'Startdatum',
      endDate: 'Einddatum',
      new: 'Nieuw',
      isActive: 'Is actief',
      city: 'Stad',
      country: 'Land',
      minimumActionBorder: 'Minimale actiegrens',
      maximumActionBorder: 'Maximale actiegrens',
      minimumRejectionBorder: 'Minimale verwerpingsgrens',
      maximumRejectionBorder: 'Maximale verwerpingsgrens',
      isActiveMonitoring: 'Is actieve monitoring',
      sortOrder: 'Sorteervolgorde',
      isActiveForRiskAssessment: 'Is actief voor risicobeoordeling',
      moistureContentConversion: 'Vochtgehalte conversie',
      analysisBaseName: 'Analysebasis naam',
      laboratory: 'Laboratorium',
      reportNumber: 'Rapportnummer',
      fileName: 'Bestandsnaam',
      fileSize: 'Bestandsgrootte',
      receivedAt: 'Ontvangen op',
      convertedAt: 'Geconverteerd op',
      importedAt: 'Geimporteerd op',
      done: 'Klaar',
      succeeded: 'Geslaagd',
      hasErrors: 'Heeft fouten',
      emailAddress: 'E-mailadres',
      attachments: 'Bijlagen',
      isEuProduct: 'EU product',
      convertedSample: 'Monster',
      startSampleDate: 'Monster startdatum',
      endSampleDate: 'Monster einddatum',
    },
    header: {
      generalInformation: 'Algemene informatie',
      feedMaterials: 'Producten',
      processingAids: 'Technische hulpmiddelen',
      flowcharts: 'Stroomdiagrammen',
      processSteps: 'Processtappen',
      processLinking: 'Proceskoppeling',
      hazards: 'Gevaren',
      standardDocuments: 'Scheme documenten',
      factSheetDocument: 'Fact sheet document',
      factSheetDocuments: 'Fact sheet documenten',
      undesirableSubstances: 'Ongewenste stoffen',
      sharedWithGroups: 'Gedeeld met groepen',
      copiedToLocations: 'Gekopieerd naar locaties',
      name: 'Naam',
      anonymous: 'Anoniem',
      belowFillInAllRequiredFieldsForThisProcessStepHazard:
        'Vul hieronder alle vereiste velden in voor deze processtap gevaar',
      belowFillInAllRequiredFieldsForThisRiskAssessment:
        'Vul hieronder alle vereiste velden in voor deze risicobeoordeling',
      belowSelectAllTheProcessingAidsThatApplyToThisRiskAssessment:
        'Selecteer hieronder alle technische hulpmiddelen die van toepassing zijn op deze risicobeoordeling',
      editHazard: 'Bewerk gevaar',
      availableProcessingAids: 'Beschikbare technische hulpmiddelen',
      addMember: 'Lid toevoegen',
      groupInvitations: 'Groepsuitnodigingen',
      activeGroups: 'Actieve groepen',
      selectedLocation: 'Geselecteerde locatie',
      controlMeasureGroup: 'Beheersmaatregelgroep',
      hazardLimits: 'Limieten',
      riskAssessments: 'Risicobeoordelingen',
      childHazards: 'Subgevaren',
      products: 'Product',
    },
    button: {
      readFullText: 'Lees volledige tekst',
      copySample: 'Kopieer Monster',
      shareSample: 'Deel Monster',
      viewSample: 'Monster bekijken',
      viewReport: 'Rapport bekijken',
      close: 'Sluiten',
      save: 'Opslaan',
      today: 'Vandaag',
      select: 'Selecteer',
      cancel: 'Annuleren',
    },
    status: {
      analysed: 'Geanalyseerd',
      copied: 'Gekopieerd',
      elapsed: 'Verstreken',
      final: 'Eind',
      planned: 'Gepland',
      taken: 'Genomen',
    },
    sample: {
      GMPCommunitySamples: 'GMP+ Gemeenschapsmonsters',
      ownSamples: 'Eigen monsters',
      copiedSamples: 'Gekopieerde monsters',
      finalise: {
        success: 'Monster succesvol afgerond',
        error: 'Er is een fout opgetreden bij het afsluiten van het monster',
        pending: 'Monster afsluiten...',
      },
      copy: {
        success: 'Monster succesvol gekopieerd',
        error: 'Er is een fout opgetreden bij het kopiëren van het monster',
        pending: 'Monster kopiëren...',
      },
      share: {
        success: 'Monster succesvol gedeeld',
        error: 'Er is een fout opgetreden bij het proberen te delen van het monster',
        pending: 'Monster wordt gedeeld...',
      },
    },
    errors: {
      anIssueOccurredWhileUpdatingTheHazard:
        'Er is een probleem opgetreden bij het bijwerken van het gevaar',
      anIssueOccurredWhileUpdatingTheRiskAssessment:
        'Er is een probleem opgetreden bij het bijwerken van de risicobeoordeling',
    },
  },
  error: {
    title: {
      notFound: 'Niet gevonden',
      serverError: 'Fout',
      unauthorized: 'Ongeautoriseerd',
      forbidden: 'Verboden',
      badRequest: 'Bad Request',
    },
    message: {
      unauthorized:
        'Sorry, je hebt niet de nodige rechten om toegang te krijgen tot dit gedeelte van het portaal. Als je denkt dat je dit bericht ten onrechte heeft ontvangen, neem dan contact op met ons ondersteuningsteam voor verdere hulp. We verontschuldigen ons voor het ongemak en helpen je graag om dit probleem snel op te lossen.',
      notFound: 'De bron kon niet worden gevonden',
      forbidden: 'Je hebt geen toestemming om deze bron te openen',
      badRequest: 'Het verzoek is ongeldig',
      serverError: 'Er is een fout opgetreden',
    },
    returnToHome: 'Ga terug naar de startpagina',
    noResultsFound: 'No results found based on your search criteria',
    youCanTryADifferentSearch:
      'Je kunt een andere zoekopdracht proberen, controleren op typfouten of uw filters wijzigen.',

    file: {
      upload: 'Er is een fout opgetreden tijdens het uploaden van het bestand',
      delete: 'Er is een fout opgetreden tijdens het verwijderen van het bestand',
      required: 'Selecteer een bestand',
    },
  },
  codelists: {
    title: 'Codelijsten',
    issuerId: {
      introText:
        '<b>issuerID</b> Codelijst met registratienummers van inzenders. GMP+ gecertificeerde laboratoria gebruiken hun GMP+ registratienummer. Niet-GMP+ laboratoria krijgen een L-nummer na aanmelding bij GMP+ International.',
    },
    countryOfOrigin: {
      introText:
        '<b>countryOfOrigin</b> Codelijst van de landen die zijn opgenomen in de GMP+ Monitoring database.',
    },
    method: {
      introText:
        '<b>method</b> Codelijst van de methodes die zijn opgenomen in de GMP+ Monitoring database.',
    },
    parameter: {
      introText:
        '<b>parameter</b> Codelijst van (on)gewenste stoffen opgenomen in de GMP+ Monitoring database. Hierin staan ook de codes (en namen) van de eenheden opgenomen die horen bij (on)gewenste stoffen. Deze heb je ook nodig voor het digitale bericht.',
    },
    product: {
      introText:
        '<b>product</b> Codelijst van producten die zijn opgenomen in de GMP+ Monitoring database.',
    },
    producerSupplier: {
      introText:
        '<b>producer/supplier</b> Codelijsten van alle GMP+ gecertificeerde producenten / leveranciers.',
    },
    introText:
      '<div>Voor het maken van het digitale bericht heb je onder andere de codes nodig van de (on)gewenste stoffen die zijn opgenomen in de GMP+ Monitoring database. Op deze pagina kunt je de benodigde codelijsten raadplegen.</div>',
    headers: {
      code: 'Code',
      country: 'Land',
      city: 'Stad',
      name: 'Naam',
      producer: 'Producent',
      unitCode: 'Eenheidscode',
      hazardType: 'Gevaarstype',
      description: 'Beschrijving',
      labType: 'Laboratoriumtype',
    },
  },
  samples: {
    headers: {
      name: 'Naam',
      level: 'Niveau',
      location: 'Locatie',
      product: 'Product',
      sampleNumber: 'Monsternummer',
      sampleDate: 'Monster datum',
      typeOfAnalysis: 'Analysetype',
      status: 'Status',
      characteristics: 'Kenmerken',
      supplier: 'Leverancier',
      producer: 'Producent',
      origin: 'Herkomst',
      batchNumber: 'Batchnummer',
      reportNumber: 'Rapportnummer',
      dateOfReport: 'Rapportdatum',
      undesirableSubstance: 'Ongewenste stof',
      method: 'Methode',
      analysedResult: 'Geanalyseerd resultaat',
      convertedResult: 'Omgezet resultaat',
      actionLimit: 'Actielimiet',
      rejectionLimit: 'Verwerpingslimiet',
      unit: 'Eenheid',
      analysisBase: 'Analysebasis',
      symbol: 'Symbool',
      moistureTested: '% Vocht getest',
      areYouSureThisSampleIsReady: 'Weet je zeker dat dit monster klaar is?',
      youAreAboutToFinaliseThisSampleAndAllItsDataYouWillNotBeAbleToEditOrDeleteThisSampleAndThisActionCannotBeUndone:
        'Je staat op het punt dit monster en alle bijbehorende gegevens af te ronden. Je kunt dit monster niet meer bewerken of verwijderen en deze actie kan niet ongedaan worden gemaakt.',
      createPlannedSample: 'Maak gepland monster',
      editPlannedSample: 'Bewerk gepland monster',
      createSample: 'Maak monster',
      editSample: 'Bewerk monster',
      create: 'Maak',
      cancel: 'Annuleren',
      save: 'Opslaan',
      createTracingData: 'Maak tracering gegevens',
      editTracingData: 'Bewerk tracering gegevens',
      analysisType: 'Analysetype',
      factsheet: 'Factsheet',
      reference: 'Referentie',
    },
    nav: {
      generalInformation: 'Algemene informatie',
      analysisReport: 'Analyserapport',
      tracingData: 'Tracering gegevens',
    },
    forms: {
      editTracingData: 'Tracering gegevens bewerken',
      addAnalysisReport: 'Analyserapport toevoegen',
      editAnalysisReport: 'Analyserapport bewerken',
      batchNumber: 'Batchnummer',
      tracingData: 'Tracering gegevens',
      origin: 'Herkomst',
      producer: 'Producent',
      supplier: 'Leverancier',
      laboratory: 'Laboratorium',
      analysisSets: 'Analysesets',
      note: 'Notitie',
      reportNumber: 'Rapportnummer',
      analysisDeviates: 'Analyse wijkt af van standaard 12% vocht',
      undesirableSubstance: 'Ongewenste stof',
      method: 'Methode',
      symbol: 'Symbool',
      result: 'Resultaat',
      AddGeneralInformation: 'Algemene informatie toevoegen',
      addTracingData: 'Tracering gegevens toevoegen',
      editGeneralInformation: 'Algemene informatie bewerken',
      dateOfReport: 'Rapportdatum',
      product: 'Product',
      sampleNumber: 'Monsternummer',
      typeOfAnalysis: 'Analysetype',
      generalInformation: 'Algemene informatie',
      publicationDate: 'Publicatiedatum',
      enterNewSample: 'Voer nieuw monster in',
      planNewSample: 'Plan nieuw monster',
      FillOutTheGeneralInformationBelow: 'Vul hieronder de algemene informatie in voor een monster',
      date: 'Datum',
      year: 'Jaar',
      month: 'Maand',
      plannedAnalysis: 'Geplande analyse',
      FillOutThePlannedAnalysisBelow:
        'Selecteer een vooraf gedefinieerd analyseset om snel een set van ongewenste stoffen te selecteren, of selecteer handmatig de ongewenste stoffen die je van plan bent te analyseren.',
      selectAnalysisSets: 'Selecteer analysesets',
      showSelectedItems: 'Toon geselecteerde items',
      characteristics: 'Kenmerken',
      analysisDeviatesTooltip:
        'Het analysetype geeft aan met welk doel het monster is genomen. {break} GMP+ analyse: Analyse uitgevoerd in het kader van de minimale verplichting onder GMP+ omstandigheden (GMP+ BA4 Minimumvoorwaarden inspectie en analyse) {break} Andere analyse: Analyse uitgevoerd in het kader van de eigen monitoring. Let op; in de toekomst zal het mogelijk zijn om uw eigen analysetypes vast te leggen.',
    },
    share: {
      doYouWantToShareThisSample: 'Wil je dit monster delen?',
      available: 'Beschikbaar',
      alreadySharedWith: 'Al gedeeld met',
      notAnonymous: 'niet Anoniem',
      anonymous: 'anoniem',
    },
    errors: {
      analysisReportNotCreated: 'Er is een fout opgetreden bij het maken van het analyserapport',
      belowFillInAllRequiredFieldsForAnSample:
        'Vul hieronder alle vereiste velden in voor een monster',
      youHaveUnsavedChangesAreYouSureYouWantToCancel:
        'Je hebt niet-opgeslagen wijzigingen. Weet je zeker dat je wil annuleren?',
    },
    months: {
      january: 'Januari',
      february: 'Februari',
      march: 'Maart',
      april: 'April',
      may: 'Mei',
      june: 'Juni',
      july: 'Juli',
      august: 'Augustus',
      september: 'September',
      october: 'Oktober',
      november: 'November',
      december: 'December',
    },
  },
  riskAssessment: {
    headers: {
      cover: 'Omslag',
      products: 'Producten',
      processingAids: 'Technische hulpmiddelen',
      flowCharts: 'Stroomdiagrammen',
      processSteps: 'Processtappen',
      processLinking: 'Proceskoppeling',
      hazards: 'Gevaren',
      name: 'Naam',
      code: 'Code',
      productGroup: 'Productgroep',
      activeRiskAssessment: 'Actieve risicobeoordeling',
      activeMonitoring: 'Actieve monitoring',
      euProduct: 'EU-product',
      processingAidsGroup: 'Technische hulpmiddelen',
      riskAssessment: 'Risicobeoordeling',
      factsheet: 'Fact sheet',
      description: 'Beschrijving',
      group: 'Groep',
      sortSequence: 'Sorteervolgorde',
      processStepName: 'Processtapnaam',
      productName: 'Product',
      previewLabel: 'Voorbeeld - Deze risicobeoordeling is nog niet gepubliceerd.',
      riskAssessments: 'Risicobeoordelingen',
      version: 'Versie',
      feedMaterials: 'Product',
      synonyms: 'Synoniemen',
      definition: 'Definitie',
      modifiedDate: 'Wijzigingsdatum',
      filterFeedMaterials: 'Filter producten',
      publicationDate: 'Publicatiedatum',
      changeDate: 'Wijzigingsdatum',
      filterProcessingAids: 'Filter technische hulpmiddelen',
      createHazardReport: 'Maak gevarenrapport',
      status: 'Status',
    },
    actions: {
      version: {
        pending: 'Aanmaken nieuwe versie',
        success: 'Nieuwe versie aangemaakt',
        error: 'Er is een fout opgetreden bij het aanmaken van een nieuwe versie',
      },
      publish: {
        pending: 'Risicobeoordeling publiceren',
        success: 'Risicobeoordeling gepubliceerd',
        error: 'Er is een fout opgetreden bij het publiceren van de risicobeoordeling',
      },
      delete: {
        pending: 'Risicobeoordeling verwijderen',
        success: 'Risicobeoordeling verwijderd',
        error: 'Er is een fout opgetreden bij het verwijderen van de risicobeoordeling',
      },
      revoke: {
        pending: 'Risicobeoordeling intrekken',
        success: 'Risicobeoordeling ingetrokken',
        error: 'Er is een fout opgetreden bij het intrekken van de risicobeoordeling',
      },
    },
    hazardReport: {
      availableFeedMaterials: 'Beschikbare producten',
      title: '(On)gewenste stoffen rapportage',
      products: 'Producten',
      hazard: '(On)gewenste stof',
      cover: 'Omslag',
      table: 'Rapport',
      description:
        'Als uw product uit meerdere producten bestaat (bijvoorbeeld een samengesteld voer), is het mogelijk om een gecombineerd gevarenrapport op te stellen. Het rapport bevat een lijst van de gevaren en in welk product ze aanwezig zijn. Zoek en selecteer het (de) betreffende product (en) in de onderstaande tabel en maak een rapport (pdf) door op de knop ‘Rapport downloaden’ in de rechterbovenhoek van het scherm te klikken.',
    },
    document: {
      processingAids: 'Technische hulpmiddelen gebruikt tijdens behandeling en verwerking*',
      processingAidsDescription: `Van de bij de productie gebruikte technische hulpstoffen dient op basis van een gevarenanalyse aangetoond te worden dat de onbedoelde, maar technisch onvermijdelijke aanwezigheid van residuen van deze technische hulpstoffen of hun derivaten in het eindproduct, geen ongunstige gevolgen hebben voor de diergezondheid, de menselijke gezondheid of het milieu en geen enkel technologisch effect op het eindproduct hebben.
      Bij het uitvoeren van de gevarenanalyse dient tevens aandacht besteed te worden aan de gevaren van overdosering van technische hulpstoffen en de aanwezigheid van contaminanten in de toegepaste technische hulpstoffen. Bij toepassing als toevoegingsmiddel vervalt bovenstaande en dient voldaan te worden aan de van toepassing zijnde communautaire wetgeving en de daarmee verenigbare nationale wetgeving.`,
      hazardOne:
        '‘Waarschijnlijk optreden’ is de kans dat een gevaar aanwezig is in het eindproduct. Je HACCP-team kan waarschijnlijk optreden bepalen op basis van metingen, observaties of verwachtingen van uw bedrijfsspecifieke situatie.',
      hazardTwo:
        'Indien {link} geen normen bevat voor een gevaar, dan moet je een norm definiëren, motiveren en documenteren. Deze norm is gebaseerd op onderzoek, bijvoorbeeld literatuurstudies, informatie uit de sector, GMP+-fact sheet over ongewenste stoffen, etc.',
      allRightsReserved:
        'Alle rechten voorbehouden. De informatie in deze publicatie mag worden geraadpleegd op het scherm, gedownload en geprint zolang dit gebeurt voor eigen, niet-commercieel gebruik. Voor ieder ander gewenst gebruik dient vooraf schriftelijke toestemming van GMP+ International B.V. te worden verkregen.',
      disclaimer: 'DISCLAIMER',
      disclaimerText:
        'Deze Risicobeoordeling is een generieke risicobeoordeling voor de producten die zijn goedgekeurd volgens de normen van het GMP+ FC-scheme. De Risicobeoordeling is afgeleid van de risicobeoordeling van de Feed Safety Database, waarbij de informatie uit deze database leidend is. Wij wijzen je erop dat bovengenoemde versiedatum de publicatiedatum is en dat de inhoud van de Risicobeoordeling de stand van zaken op die datum weergeeft. Beschouw deze Risicobeoordeling als een ‘levend document’ en suggesties voor verbeteringen zijn meer dan welkom.',
      disclaimerTextTwo: `Hoewel bij de voorbereiding van deze Risicobeoordeling alle voorzorgsmaatregelen zijn genomen en alle inspanningen zijn verricht, is de juistheid van de in deze Risicobeoordeling vermelde informatie onder voorbehoud van wijzigingen en verschillen. GMP+ International aanvaardt geen aansprakelijkheid voor eventuele fouten of omissies, noch voor verliezen of ongemakken als gevolg van dergelijke wijzigingen of verschillen.`,
      enFallback:
        'Let op: Het originele stroomdiagram is niet beschikbaar in de gevraagde taal. Een Engelse versie is hieronder voor uw gemak verstrekt. Onze excuses voor het ongemak.',
      noFallback:
        'Helaas is het stroomdiagram dat je zoekt momenteel niet beschikbaar in jouw taal. We werken eraan om deze inhoud zo snel mogelijk beschikbaar te maken. Bedankt voor uw geduld en begrip.',
    },
    introText:
      "Welke risico's zijn verbonden aan de productie van voedermiddelen? In samenwerking met GMP+ gecertificeerde bedrijven is een aantal risicobeoordelingen ontwikkeld. Er is er één voor elk geaccepteerd voedermiddel op de Productlijst van GMP+ International",
  },
  processingAids: {
    introText: `<div>Deze sectie van de database bevat fact sheets over technische hulpmiddelen. Technische hulpmiddelen worden gebruikt bij de productie van producten. Het gebruik van technische hulpmiddelen is toegestaan binnen de GMP+-context, mits de gebruiker met een gevarenanalyse aantoont dat de onbedoelde, maar technisch onvermijdelijke aanwezigheid van residuen of hun derivaten geen invloed heeft op de diergezondheid, de menselijke gezondheid of het milieu en geen technologisch effect heeft op het eindproduct.</div>
      <div>De fact sheets bevatten details over het uitvoeren van de gevarenanalyse. Dit bevat een sectie over eventuele ongewenste stoffen die aanwezig zijn in diervoeder en de effecten ervan op mensen, dieren en het milieu; beheersmaatregelen die kunnen worden genomen; en de risico's die kunnen voortvloeien uit hoge of lage doses van technische hulpmiddelen.</div>
      <div>Een overzicht is bijgevoegd waarin alle technische hulpmiddelen die bij de productie van producten worden gebruikt, worden weergegeven. De technische hulpmiddelen zijn onderverdeeld in een aantal categorieën. Een fact sheet is beschikbaar voor technische hulpmiddelen die gemarkeerd zijn met een downloadpictogram.</div>`,
    aboutProcessingAids: 'Over technische hulpmiddelen',
    factsheetIncluded: 'Fact sheet inbegrepen',
    included: 'Inbegrepen',
    notIncluded: 'Niet inbegrepen',
    showRiskAssessments: 'Toon risicobeoordelingen',
    headers: {
      name: 'Naam',
      groups: 'Groepen',
    },
  },
  products: {
    headers: {
      name: 'Naam',
      code: 'Code',
      synonyms: 'Synoniemen',
      definition: 'Definitie',
      productGroup: 'Groep',
    },
    category: {
      none: 'Geen',
      feedMaterial: 'Voedermiddelen',
      additives: 'Toevoegingsmiddelen',
      premixtures: 'Voormengsels',
      compoundFeeds: 'Mengvoeders',
    },
  },
  hazards: {
    headers: {
      name: 'Naam',
      code: 'Code',
      category: 'Categorie',
      severity: 'Ernst',
      sortSequence: 'Sorteervolgorde',
      limits: 'Limieten',
      factsheet: 'Fact sheet',
      hazardGroup: 'Gevarengroep',
      definitions: 'Definities',
      synonyms: 'Synoniemen',
      riskAssessments: 'Risicobeoordelingen',
      monitoringDatabaseResults: 'Resultaten van monitoringdatabase',
      factsheetIncluded: 'Fact sheet inbegrepen',
      isActiveForRiskAssessment: 'Actief voor risicobeoordeling',
      isActiveMonitoring: 'Actief voor monitoring',
    },
    analysisBase: {
      none: 'Geen',
      moist12Percent: '12% vocht',
      dryMatter: 'Droge stof',
      fatBasis: 'Vetbasis',
      product: 'Product',
      protein: 'Proteïne',
    },
    high: 'Hoog',
    medium: 'Medium',
    low: 'Laag',
    limitsText:
      'Er zijn limieten van toepassing op dit gevaar. Je kunt deze limieten bekijken in het fact sheet, dat downloadbaar is via de knop in de rechterbovenhoek. Wil je meer lezen over alle Specifieke Voederveiligheidslimieten? Download het volledige document.',
    noLimitsNoFactSheet:
      'Deze ongewenste stof heeft geen vastgestelde GMP+-limieten. U moet een limiet vaststellen, motiveren en documenteren. Deze limiet is gebaseerd op onderzoek, bijvoorbeeld literatuurstudies, informatie uit de sector, enzovoort.',
    noLimits:
      'Deze ongewenste stof heeft geen vastgestelde GMP+ limieten. U dient een limiet vast te stellen, te motiveren en te documenteren. Deze limiet is gebaseerd op onderzoek, bijvoorbeeld literatuurstudies, informatie uit de sector, GMP+ factsheet van ongewenste stoffen (downloadbaar via de knop rechtsboven), etc. \nDe factsheet beschrijft de eigenschappen en effecten van ongewenste stoffen. Daarnaast bevat het een uitleg over de ernst van een gevaar, details over hoe de analyse moet worden uitgevoerd, en welke beheersmaatregelen kunnen worden genomen om te voorkomen dat de ongewenste stof in het diervoeder terechtkomt.',
    factSheetAndLimits:
      'De factsheet (downloadbaar via de knop rechtsboven) beschrijft de eigenschappen en effecten van ongewenste stoffen. Daarnaast bevat het een uitleg over de ernst van een gevaar, details over hoe de analyse moet worden uitgevoerd, en welke beheersmaatregelen kunnen worden genomen om te voorkomen dat de ongewenste stof in het diervoeder terechtkomt.',
    aboutUndesirableSubstances: 'Over ongewenste stoffen',
    downloadFactsheet: 'Download fact sheet',
    downloadStandardDocument: 'Specifieke voederveiligheidsnormen',
    introText: `<div>Het kwaliteitsbeleid van GMP+ International richt zich op het verminderen van de aanwezigheid van ongewenste stoffen in diervoeder. Echter, een groot aantal stoffen kan, natuurlijk of niet, aanwezig zijn in bepaalde producten. De risicobeoordelingen geven deze ongewenste stoffen aan als een gevaar.</div>
    <div>Deze sectie bevat een groot aantal fact sheet die de eigenschappen en effecten van ongewenste stoffen beschrijven. Daarnaast bevat het details over de ernst van een gevaar, hoe de analyse moet worden uitgevoerd en welke beheersmaatregelen kunnen worden genomen om te voorkomen dat de ongewenste stof in het diervoeder terechtkomt. In de lezer wordt voor elk hoofdstuk uitgelegd welke informatie in de fact sheets kan worden geraadpleegd.</div>
    <h2 class="text-primary text-xl font-bold">Specifieke studies</h2>
    <ul class="text-primary underline flex flex-col gap-2">
      <li><a href="/media/S 9.71 Studie naar droogprocessen voor diervoedergrondstoffen en HACCP.pdf" target="_blank" rel="noopener noreferrer">S 9.71 Studie naar droogprocessen voor diervoedergrondstoffen en HACCP</a></li>
      <li><a href="/media/S 9.72 Zouten in rantsoenen met natvoer voor mestvarkens en zeugen.pdf" target="_blank" rel="noopener noreferrer">S 9.72 Zouten in rantsoenen met natvoer voor mestvarkens en zeugen</a></li>
      <li><a href="/media/S 9.73 Uitwerking van grenswaarden voor PAK's in olie voor voer.pdf" target="_blank" rel="noopener noreferrer">S 9.73 Uitwerking van grenswaarden voor PAK's in olie voor voer</a></li>
      <li><a href="/media/S 9.74 Overdracht van Polycyclische Aromatische Koolwaterstoffen in olie voor voer.pdf" target="_blank" rel="noopener noreferrer">S 9.74 Overdracht van Polycyclische Aromatische Koolwaterstoffen in olie voor voer</a></li>
      <li><a href="/media/S 9.75 Norm voor schimmelbelasting in voer.pdf" target="_blank" rel="noopener noreferrer">S 9.75 Norm voor schimmelbelasting in voer</a></li>
    </ul>`,
  },
  buttons: {
    copySample: 'Kopieer Monster',
    shareSample: 'Deel Monster',
    downloadCsv: 'Download CSV',
    exportCsv: 'Exporteer CSV',
    downloadPdf: 'Download pdf',
    edit: 'Bewerken',
    deleteSample: 'Verwijder Monster',
    addAndFillSection: 'Sectie toevoegen en invullen',
    tasks: 'Taken',
    help: 'Help',
    finalize: 'Afronden',
    save: 'Opslaan',
    add: 'Toevoegen',
    addNewLine: 'Nieuwe Regel toevoegen',
    noDoNotShare: 'Nee, niet delen',
    yesShare: 'Ja, delen',
    close: 'Sluiten',
    noCancel: 'Nee, annuleren',
    yesRevoke: 'Ja, intrekken',
    yesFinalize: 'Ja, afronden',
    revoke: 'Intrekken',
    yesPublish: 'Ja, publiceren',
    publish: 'Publiceren',
    cancel: 'Annuleren',
    noContinue: 'Nee, doorgaan',
    yesCancel: 'Ja, annuleren',
    moreInfo: 'Meer info',
    readManual: 'Lees handleiding',
    yesDelete: 'Ja, verwijderen',
    requestMissingCode: 'Vraag ontbrekende code aan',
    createNewVersion: 'Maak nieuwe versie',
    preview: 'Voorbeeld',
    revokePublishedVersion: 'Intrekken gepubliceerde versie',
    next: 'Volgende',
    prev: 'Vorige',
    delete: 'Verwijderen',
    update: 'Bijwerken',
    copy: 'Copy',
    share: 'Share',
    applyToAll: 'Toepassen op alle',
    addNewMember: 'Nieuw lid toevoegen',
    accept: 'Accepteren',
    reject: 'Afwijzen',
  },
  modals: {
    areYouSureYouWantToRevokeThePublishedVersion:
      'Weet je eker dat je de gepubliceerde versie wilt intrekken?',
    youAreAboutToRevokeTheFollowingRiskAssessment:
      'Je staat op het punt de volgende risicobeoordeling in te trekken',
    doingSoWill: 'Hierdoor wordt',
    setTheStatusToRevokedInTheManagementPortal:
      'De status op ingetrokken gezet in het managementportaal',
    removeThePublishedVersionOfTheClientPortalAndThereforeMakeItNotAccessibleForClientsAnymore:
      'De gepubliceerde versie van het clientportaal verwijderd en daardoor niet meer toegankelijk voor klanten',
    featureComingSoon: 'Functie binnenkort beschikbaar',
    featureComingSoonDescription:
      'We werken hard om je nieuwe functies te bieden. Deze functionaliteit zal binnenkort beschikbaar zijn. Bedankt voor je geduld en begrip.',
    deleteRiskAssessmentsTitle: 'Weet je zeker dat je deze risicobeoordeling wilt verwijderen?',
    deleteRiskAssessmentsDescription:
      'Weet je zeker dat je deze risicobeoordeling wilt verwijderen? Als er al een gepubliceerde versie van de risicobeoordeling bestaat, wordt deze niet verwijderd en in dat geval wordt alleen de laatste werkversie verwijderd.',
    publishRiskAssessmentsTitle: 'Weet je zeker dat je deze risicobeoordeling wilt publiceren?',
    youAreAboutToPublishTheFollowingRiskAssessment:
      'Je staat op het punt de volgende risicobeoordeling te publiceren:',
    andAllItsDataThisAssessmentCanNoLongerBeChangedWhileBeingPublished:
      'en alle bijbehorende gegevens. Deze beoordeling kan niet meer worden gewijzigd tijdens publicatie.',
    afterPublishingItIsOptionalToWithdrawThePublishedVersionOrCreateANewVersionBasedOnThePublishedVersionToImplementAtALaterTime:
      'Na publicatie is het optioneel om de gepubliceerde versie in te trekken of een nieuwe versie te maken op basis van de gepubliceerde versie om deze later te implementeren.',
  },
  tasks: {
    tasks: 'Taken',
    finishGeneralInfo: 'Algemene informatie voltooien',
    continueByFillingOutTheRemainingSampleInformation:
      'Ga verder door de resterende monsterinformatie in te vullen',
    tracingData: 'Tracering gegevens',
    startHereByProvidingTracingDataForYourNewSample:
      'Begin hier door tracering gegevens voor uw nieuwe monster te verstrekken',
    analysisReport: 'Analyserapport',
    youHaveCompletelyFilledThisSample: 'Je hebt dit monster volledig ingevuld',
    allSectionsHaveBeenFilledOutAndAreMarkedAsDone:
      'Alle secties zijn ingevuld en gemarkeerd als voltooid',
    enterAnalysisDataInToAnAnalysisReport: 'Voer analysedata in een analyserapport in',
    thisSampleIsNowComplete: 'Dit monster is nu voltooid',
    createSample: 'Monster aanmaken',
    yesConvert: 'Ja, omzetten',
    areYouSureYouWantToCreateASampleOfThisPlannedSample:
      'Weet je zeker dat je van dit geplande monster een monster wilt maken?',
    turnThisPlannedSampleIntoASample: 'Dit geplande monster omzetten in een monster',
    undesirableSubstances: 'Ongewenste stoffen',
    addAndFillSection: 'Sectie toevoegen en invullen',
    doYouWantToShareThisSample: 'Wil je dit monster delen?',
    shareSample: 'Deel monster',
    yesShare: 'Ja, delen',
    noDoNotShare: 'Nee, niet delen',
  },
  errors: {
    invalid_type: 'Dit resultaat heeft niet het juiste invoerformaat',
    required: 'Dit veld is verplicht',
    csv: 'Er ging iets mis met het exporteren van het CSV-bestand',
  },
  feedSchemeDocuments: {
    title: 'GMP+ scheme documenten',
    introText:
      '<div>Hier vind je de GMP+ scheme documenten. Je kunt het document online bekijken of de pdf-versie downloaden. Documenten zijn beschikbaar in het Engels, Nederlands en Duits. Maak gebruik van je browser vertaalfunctie om de documenten in andere talen te vertalen.</div>',
    version: 'versie',
    versionCompare: 'verschil',
    versionCompareBanner:
      'Je bekijkt een {versionCompare} tussen de huidige versie ({currentVersion}) en de vorige versie ({previousVersion}).',
    viewOnline: 'Bekijk online',
    download: 'Downloaden',
    schemeDocument: 'Scheme document',
    compare: 'Vergelijk versies',
    downloadVersionDiffPdf: 'Download versieverschil (pdf)',
    closeVersionDiff: 'Sluit versieverschil',
    document: {
      version: 'Versie',
    },
  },
  feedMaterials: {
    nav: {
      productInformation: 'Productinformatie',
      analysisReport: 'Analyserapport',
      tracingData: 'Tracering gegevens',
      riskAssessments: 'Risicobeoordelingen',
    },
    definitions: 'Definities',
    definition: 'Definitie',
    name: 'Naam',
    code: 'Code',
    synonyms: 'Synoniemen',
    riskAssessments: 'Risicobeoordelingen',
    monitoringDatabaseResults: 'Resultaten van monitoringdatabase',
    feedMaterialsThisFeedMaterialIsNotAuthorisedInTheEUCheckLegalStatusInOtherCountries:
      'Dit product is niet toegestaan in de EU. Controleer de wettelijke status in andere landen.',
    showTheAssessments: 'Toon de beoordelingen',
    publicationDate: 'Publicatiedatum',
  },
  greeting: {
    morning: 'Goedemorgen',
    afternoon: 'Goedemiddag',
    evening: 'Goedenavond',
  },
  analysisSets: {
    headers: {
      name: 'Naam',
      location: 'Locatie',
      status: 'Status',
      detectionLimit: 'Detectielimiet',
      method: 'Methode',
      unit: 'Eenheid',
    },
    errors: {
      belowFillInAllRequiredFieldsForAnalysisSets:
        'Selecteer hieronder alle ongewenste stoffen die in dit analysepakket moeten worden opgenomen',
    },
    forms: {
      generalInformation: 'Algemene informatie',
      availableUndesirableSubstances: 'Beschikbare ongewenste stoffen',
      showSelectedItem: 'Toon geselecteerde items',
      selectUndesirableSubstance: 'Selecteer ongewenste stof',
      selectMethod: 'Selecteer methode',
    },
    createAnalysisSet: 'Analyseset aanmaken',
    next: 'Volgende',
    prev: 'Vorige',
    generalInformation: 'Algemene informatie',
    editAnalysisSet: 'Analyseset bewerken',
  },
  dashboard: {
    hub: {
      welcome: 'Welkom op het Management Dashboard',
      welcomeDescription:
        'Welkom op uw Management Dashboard! Hier heb je de tools en bronnen binnen handbereik om effectief masterdata te beheren, uitgebreide risicobeoordelingen uit te voeren en andere kritieke aspecten van uw operaties te overzien. Dit portaal is ontworpen om je te voorzien van gestroomlijnde workflows en realtime gegevens, zodat je weloverwogen beslissingen kunt nemen en optimale operationele efficiëntie kunt behouden. Verken de beschikbare functies en verbeter vandaag nog uw managementcapaciteiten.',
    },
    portal: {
      welcome: 'Welkom op de GMP+ Portal',
      welcomeDescription:
        'Bedankt voor uw bezoek aan de nieuwe GMP+ Portal. Dit platform is ontworpen om uw toegang tot onze uitgebreide bronnen, tools en updates op het gebied van voederveiligheidsbeheer te stroomlijnen. Ons doel is om je te ondersteunen bij het handhaven van de hoogste normen van kwaliteit en veiligheid. Verken het portaal om op maat gemaakte oplossingen en deskundige begeleiding te vinden die uw operaties kunnen verbeteren. Wij zijn er om je bij elke stap te ondersteunen.',
      provideYourFeedback: 'Geef uw feedback',
      doYouHaveAnyFeedbackForUs:
        'Heb je feedback voor ons? Door op deze knop te klikken kun je ons op de hoogte stellen van eventuele problemen.',
      feedback: 'Feedback',
      demo: 'Demo',
      startADemoTourOfTheNewOnlineEnvironment: 'Start een demo tour van de nieuwe online omgeving.',
      startDemo: 'Demo starten',
      academy: 'GMP+ Academy',
      joinAcademy:
        'Sluit je aan bij GMP+ Academy en verbeter de wereldwijde voederveiligheid en duurzaamheid! Toegang tot experttrainingen, internationale trainers en online cursussen!',
      academyLink: 'https://www.gmpplusacademy.org/',
    },
    certifiedCompanies: 'GMP+ Company database',
    feedSchemeDocuments: 'GMP+ scheme documenten',
    riskAssessments: 'Risicobeoordelingen',
    aboutDescription:
      'GMP+ International deelt waardevolle kennis over voederveiligheid met gecertificeerde bedrijven via onze Feed Support Products (FSP). Dit is een bron van informatie die iedereen in de voederketen ten goede komt.',
    about: 'Over',
    manageRiskAssessments: 'Beheer risicobeoordelingen',
    manageHazards: 'Beheer gevaren',
    manageProducts: 'Beheer producten',
  },
  allowedFeedMaterials: {
    introText:
      'In dit gedeelte van de Risico management tools vind je de productlijst. Dit is de lijst van geaccepteerde voedermiddelen die binnen de GMP+ gecertificeerde keten geproduceerd en verhandeld kunnen worden.',
    createHazardReport: 'Maak een risicorapport',
  },
  monitoringGroup: {
    introText:
      'In de GMP+ Monitoring database zijn voorzieningen getroffen voor de samenwerking van verschillende bedrijven. Deze groepen kunnen bestaan uit leden van een vereniging, verschillende locaties van één bedrijf of een leverancier met zijn klanten. Hieronder staan de groepen waarvan je lid bent.',
    headers: {
      group: 'Groep',
      startDate: 'Startdatum',
      endDate: 'Einddatum',
      dateAgreedByLocation: 'Datum overeengekomen',
      status: 'Status',
      authorized: 'Groep gemachtigd voor invoer',
    },
    applyForGroupManager: {
      title: 'Aanmelden voor de rol van ‘Groepsbeheerder’',
      text: 'Als je een groep wil beheren, maar je hebt geen groepsbeheerder-account heeft, dan kun je je aanmelden met',
      button: 'dit formulier',
    },
  },
  managementGroupMembers: {
    introText:
      'Hieronder vind je een overzicht van de leden (beter bekend als locaties) van de groep waarvan je manager bent. Hier kun je het lidmaatschap van de groepsleden bewerken. Als groepsmanager kun je nieuwe leden uitnodigen voor de groep en het lidmaatschap van huidige leden intrekken.',
    note: 'Let op: Als de groepsmanager gemachtigd is om resultaten namens een locatie in te voeren, kan hij/zij dit doen in het monitoringoverzicht ‘Mijn monitoring‘.',
    locationsInGroup: 'Locaties in groep',
    noAdminGroups: 'Het lijkt erop dat je momenteel geen groepen beheert.',
    headers: {
      registratioNr: 'Registratienummer',
      location: 'Locatie',
      startDate: 'Startdatum',
      endDate: 'Einddatum',
      agreedLocation: 'Overeengekomen locatie',
      status: 'Status',
      groupAuthorizedForInput: 'Groep gemachtigd voor invoer',
      group: 'Groep',
      monitoringGroup: 'Monitoring groep',
      addMember: 'Lid toevoegen',
    },
    step: [
      {
        text: 'Je bent ingelogd als groepsbeheerder. Selecteer hieronder alle locaties die je wilt toevoegen als nieuwe leden van de groep {groupname}. De begin- en einddatums kunnen in de volgende stap worden bepaald.',
      },
      {
        text: 'Stel een begin- en einddatum in voor het lidmaatschap of stel ze afzonderlijk in.',
      },
    ],
  },
  select: {
    salmonella: {
      title: 'Selecteer het type Salmonella',
      intro: 'Specificeer welk type Salmonella werd gevonden tijdens de analyse:',
      search: 'Zoek salmonella type',
    },
    location: {
      title: 'Kopieer monster naar locatie',
      intro: 'Selecteer een locatie uit de onderstaande lijst:',
      search: 'Zoek locatie',
      share: 'Kopieer',
    },
    sampleShare: {
      title: 'Monster delen met monitoringsgroep',
      intro: 'Selecteer een monitoringsgroep uit de onderstaande lijst:',
      search: 'Zoek monitoringsgroep',
      share: 'Delen',
      shareCommunity: 'Delen met de GMP+ gemeenschap',
    },
    hazardType: {
      Value: 'Waarde',
      Toggle: 'Aanwezig/Afwezig',
    },
  },
  externalLinks: {
    earlyWarningSystem: 'https://portal.gmpplus.org/nl-NL/ews-support/ews-cases/',
    companyDatabase: {
      certifiedCompanies: 'https://portal.gmpplus.org/nl-NL/cdb/certified-companies/',
      certificationBodies: 'https://portal.gmpplus.org/nl-NL/cdb/certification-body/',
      laboratories: 'https://www.gmpplus.org/nl/feed-certification-scheme/company-database/lab/',
      partners: 'https://portal.gmpplus.org/nl-NL/cdb/partners/',
      otherCertificationSchemes:
        'https://portal.gmpplus.org/nl-NL/cdb/other-certification-schemes/',
    },
    missingCodes: 'https://fd8.formdesk.com/gmpinternational/aanvraag_codes_monitoring_nl',
    applyForGroupManager:
      'https://fd8.formdesk.com/gmpinternational/Aanvragen_account_voor_groepsbeheerder',
    TS1: 'https://www.gmpplus.org/feed-certification-scheme/scheme-documents?id=d0c22ef7-9577-f05f-dafc-8904d9d7ea9e&language=nl',
    cbLogin: 'https://portal.gmpplus.org/nl-NL/',
    exemptions: 'https://portal.gmpplus.org/nl-NL/my-exemptions/',
  },
  toasts: {
    create: {
      pending: '{name} aanmaken...',
      error: 'Er ging iets mis bij het aanmaken van {name}',
      success: '{name} succesvol aangemaakt',
    },
    update: {
      pending: 'Updaten {name}...',
      error: 'Er ging iets mis bij het updaten van {name}',
      success: '{name} succesvol geupdated',
    },
    report: {
      pending: 'Rapport aan het laden...',
      error: 'Er ging iets mis bij het laden van het rapport',
      success: 'Rapport succesvol geladen',
    },
    csv: {
      pending: 'CSV export aanmaken...',
      error: 'Er ging iets mis met het exporteren van het CSV-bestand',
      success: 'Exporteren van het CSV-bestand is gelukt',
    },
    copyLink: {
      success: 'Link naar het voorbeeld gekopieerd naar klembord',
      error: 'De link naar het voorbeeld kon niet worden gekopieerd',
    },
  },
  filters: {
    factsheetIncluded: 'Fact sheet inbegrepen',
  },
}
